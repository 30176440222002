module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-transformer-remark","options":{"tableOfContents":{"heading":null,"maxDepth":6}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1500,"sizeByPixelDensity":true,"linkImagesToOriginal":false,"disableBgImage":true,"loading":"lazy"}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}}},{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"300","removeAccents":true}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/xthreen/work/copperhead.co/src"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Copperhead","short_name":"copperhead","start_url":"/","background_color":"#000000","theme_color":"#a2466c","display":"minimal-ui","icon":"static/img/icons/main.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6be1f1cec9963784759e4deee7370841"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137811622-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"strategy":"selfHosted","useMinify":true,"usePreload":true,"families":["Big Shoulders Text Bold","Big Shoulders Text ExtraBold","Fira Sans Regular","Fira Sans Light"],"urls":["/fonts/BigShouldersText-Bold.css","/fonts/BigShouldersText-ExtraBold.css","/fonts/FiraSans-Light.css","/fonts/FiraSans-Regular.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/blog/*","/android/*","/android/docs/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://copperhead.co","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
